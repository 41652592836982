.chat-message * {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.chat-message {
  max-width: 400px !important;
  overflow: scroll;
}

.chat-message h1,
.chat-message h2,
.chat-message h3 {
  color: #333;
  margin: 10px 0;
  font-weight: 600;
}

.chat-message h1 {
  font-size: 1.5em;
}

.chat-message h2 {
  font-size: 1.3em;
}

.chat-message h3 {
  font-size: 1.2em;
}

.chat-message p {
  margin: 10px 0;
  display: inline;
}

.chat-message strong {
  font-weight: bold;
}

.chat-message em {
  font-style: italic;
}

.chat-message a {
  color: #007bff;
  text-decoration: none;
}

.chat-message a:hover {
  text-decoration: underline;
}

.chat-message ol > li::marker {
  font-weight: bold;
}

.chat-message code {
  font-family: "Courier New", monospace;
  background-color: #eee;
  padding: 3px;
  border-radius: 3px;
}

.chat-message pre {
  background-color: #eee;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}

.chat-message blockquote {
  margin-left: 20px;
  color: #777;
  border-left: 3px solid #ddd;
  padding-left: 15px;
}

.chat-message ul,
.chat-message ol {
  padding-left: 20px;
}

.chat-message img {
  max-width: 100%;
}

.chat-message table img {
  max-width: 64px;
  max-height: 64px;
}

.chat-message table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.chat-message th,
.chat-message td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.chat-message th {
  background-color: #f4f4f4;
  color: #333;
}
